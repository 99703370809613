.Sidebar {
    position: fixed;
    width: 70%;
    min-width: 175px;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: #EEF0F1;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    border: 2px solid #07689F;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.50);
}

@media (min-width: 550px) {
    .Sidebar {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}