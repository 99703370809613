.LandingScreen {
    width: 100%;
    height: 100%;
    background-color: #07689F;
    display: inline-block;
}

.Box {
    background: #EEF0F1;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.50);
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
    margin: auto;
    margin-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    display: block;
    height: auto;
    overflow: visible;
    align-content: center;
}

.Box h1 {
    text-align: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
    color: #444444;
    font-family: 'Open Sans';
}

.Box p {
    width: 70%;
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
    color: #444444;
    font-family: 'Open Sans';
    font-size: 18px;
}

.ButtonContainer {
    background: #EEF0F1;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.50);
    border-radius: 5px;
    width: 30%;
    margin: auto;
    margin-top: 30px;
    height: 60px;
    min-width: 250px;
    max-width: 400px;
}
