.SettingsScreen {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.SignOutButtonContainer {
    width: 50%;
    height: auto;
    margin: auto;
    margin-top: 0px;
    align-content: center;
}

.HideCompletedBox {
    width: 45%;
    height: auto;
    margin: auto;
    margin-top: 50px;
    background-color: #EEF0F1;
    padding: 15px;
    border: 1px solid #07689F;
    border-radius: 10px;
    text-align: center;
    display: inline-flexbox;
}

.HideCompletedBox p {
    font-size: 16px;
    color: #444444;
    font-family: 'Open Sans';
    text-align: center;
    min-width: 160px;
}

.SwitchContainer {
    margin: auto;
}

@media (min-width: 550px) {
    .HideCompletedBox {
        border: 2px solid #07689F;
    }

    .HideCompletedBox p {
        font-size: 20px;
    }
}

@media (min-width: 900px) {

    .HideCompletedBox p {
        font-size: 21px;
    }
}