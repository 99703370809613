.FullTask {
    background: #EEF0F1;
    border: 1px solid #07689F;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    align-self: center;
    text-align: left;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 20px;
    box-shadow: 1px 1px rgba(0,0,0,0.5);
    display: block;
    height: auto;
    overflow: visible;
    z-index: 100;
}


.FullTaskContentArea2 {
    width: 100%;
}

.TopArea {
    margin: 15px;
    display: flex;
    height: auto;
    width: auto;
    justify-content: space-between;
    position: relative;
}

.TopArea textarea {
    text-decoration: none;
    margin: auto;
    align-content: center;
    padding: auto;
    margin-left: 0;
    margin-right: 5px;
    font-weight: 600;
    font-size: 18px;
    color: #444444;
    display: inline-flex;
    overflow: visible;
    width: 75%;
}

.TextArea {
    height: auto;
    margin: auto;
    margin-left: 0px;
    font-weight: 600;
    font-size: 18px;
    color: #444444;
    align-content: center;
    width: 90%;
    border: none;
    background-color: transparent;
    display: inline-block;
    text-align: left;
    font-family: 'Open Sans';
    resize: none;
    vertical-align: baseline;
}

.TextArea textarea {
    resize: none;
}

.TopArea button {
    border: none;
    background-color: transparent;
    height: auto;
    padding: 0;
    margin: auto 0 auto 0;
    
}

.ListArea {
    display: flex;
    padding: 15px 15px 15px 20px;
}

.ListArea button {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.Dropdown-root {
    position: relative;
    padding-left: 15px;
    font-size: 18px;
    font-family: 'Open Sans';
    font-weight: 600;
    text-align: left;
    width: 100%;
    margin: auto;
}
  
.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: #444444;
  cursor: default;
  outline: none;
  padding: 12px 30px 12px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  color: #07689F;
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 15px;
  top: 23px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: #EEF0F1;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin-top: -1px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 200px;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title{
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
    box-sizing: border-box;
    color: #666666;
    cursor: pointer;
    display: block;
    padding: 10px 15px;
  }
  
  .Dropdown-menu {
    margin: auto;
    text-align: center;
    width: 100%;
  }
  
  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
  }
  
  .Dropdown-option:hover {
    color: #07689F;
  }
  
  .Dropdown-option.is-selected {
    color: #07689F;
  }
  
  .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }

.DateArea {
    margin:0px;
    padding:0px 15px 15px 20px;
    display: flex;
    height: auto;
    width: auto;
    position: relative;
}


.DateAreaButton {
    text-align: left;
    margin:0;
    margin-right: 15px;
    padding-right: 0;
    padding-left: 0;
    border: none;
    background-color: transparent;
    height: 90%;
    width: auto;
}

.DatePickerStyling {
    height: 25px;
    margin-top: 4px;
    background-color: transparent;
    border: 0;
    font-weight: 500;
    font-size: 16px;
    color: #444444;
}

.DatePickerStylingPast {
    height: 25px;
    margin-top: 4px;
    background-color: transparent;
    border: 0;
    font-weight: 500;
    font-size: 16px;
    color: #DA3A3B;
}


.NoteArea {
    margin:0px;
    padding-top:5%;
    padding-left:5%;
    padding-right:5%;
    height: 250px;
    width: 90%;
}

.NoteTextArea {
    width:95%;
    height: 100%;
    margin-top: auto;
    margin-bottom:auto;
    border: none;
    font-weight: 500;
    font-size: 15px;
    color: #444444;
    background-color: transparent;
    display: inline-block;
    text-align: left;
    font-family: 'Open Sans';
    resize: none;
    vertical-align: baseline;
}

.Note textarea {
    resize: none;
    height: 100%;
}

.BottomArea {
    margin:0px;
    padding-bottom: 15px;
    display: flex;
    height: auto;
    width: 90%;
}

.BottomArea time {
    text-align: center;
    width: 85%;
    font-family: 'Open Sans';
    font-weight: 500;
    font-size: 14px;
    color: #444444;
    height: auto;
    margin: auto;
}

.RemoveButton {
    border: none;
    background-color: transparent;
    height: auto;
    padding: 0;
    margin: auto 0 auto 0;
}


@media (min-width: 550px) {
    .FullTask {
        border: 2px solid #07689F;
        margin-top: 110px;
    }

    .TopArea {
        margin:20px 20px 5px 20px;
    }

    .TopArea textarea {
        margin-right: 10px;
        width: 85%;
        font-size: 20px;
    }

    .OptionsArea {
        display: flex;
        justify-content: space-evenly;
    }

    .DateArea {
        padding:0px 20px 0px 20px;
    }

    .DateAreaButton {
        height: auto;
    }

    .ListArea {
        width: 40%;
    }


    .TextArea {
        font-size: 20px;
    }

    .DatePickerStyling {
        height: 100%;
        font-size: 18px;
        margin: auto;
        margin-top: 26px;
        font-weight: 600;
        width: 90%;
    }

    .DatePickerStylingPast {
        margin: auto;
        height: auto;
        font-size: 18px;
    }


    .NoteTextArea {
        font-size: 16px;
    }

    .BottomArea {
        margin: 20px;
        width: 90%;
        padding-bottom: 0px;
    }

    .BottomArea p {
        font-size: 16px;
    }
}


@media (min-width: 900px) {
    .FullTask {
        border: 3px solid #07689F;
        margin-top: 130px;
    }

    .TextArea {
        font-size: 23px;
    }

    .TopArea textarea {
        font-size: 21px;
    }

    .TopArea {
        margin: 25px 25px 10px 25px;
    }

    .BottomArea {
        margin: 25px;
    }

    .DateArea {
        padding:0px 25px 0px 25px;
    }
}