.AnalyticsScreen {
    width: 100%;
    height: 100%;
    background-color: #A2D5F2;
    display: inline-block;
}

.Box {
    width: 80%;
    max-width: 400px;
    margin: auto;
    margin-top: 25px;
    background-color: #EEF0F1;
    padding: 15px;
    border: 1px solid #07689F;
    border-radius: 15px;
    text-align: center;
    display: inline-flexbox;
    height: auto;
    align-items: center;
}

.TasksCompletedBox {
    width: 80%;
    max-width: 400px;
    margin: auto;
    margin-top: 50px;
    background-color: #EEF0F1;
    padding: 10px;
    border: 1px solid #07689F;
    border-radius: 15px;
    text-align: center;
    display: inline-flexbox;
    height: auto;
}

.Box p {
    font-family: 'Open Sans';
    color: #07689F;
    font-weight: 700;
    font-size: 18px;
}

@media(min-width: 850px) {

    .AnalyticsScreen {
        display: flex;
    }
    
    .Box {
        margin-top: 50px;
        padding: 15px;
    }

    .TasksCompletedBox {
        padding: 15px;
    }

    .Box p {
        font-size: 24px;
    }
}

@media(min-width: 900px) {
    .Box p {
        font-size: 28px;
    }
}